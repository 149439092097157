export function buildBundledConfig(
  env: Record<string, string | undefined> = {},
  { LOGGER_SOURCE = '¢', PORT = '3001', VERSION = 'next' } = {},
) {
  /**
   * !!! IMPORTANT --- NEVER SHARE SECRETS HERE --- IMPORTANT !!!
   *
   * Add any additional global configuration in the object below.
   * This object is injected in the client & server as the `clientConfig` global.
   *
   * --- Keep in mind the following rules ---
   *
   * For booleans, always compare with 'true', ex:
   *  env.NEXT_PUBLIC_USE_EXAMPLE === 'true'
   *
   * For numbers, use parseInt or Number constructor to convert strings to numbers, ex:
   *  parseInt(env.NEXT_PUBLIC_EXAMPLE_NUMBER || '20000')
   *
   * Every input from env is either a string or undefined.
   */
  const NODE_ENV = env.NODE_ENV || 'production';
  const IS_DEVELOPMENT = NODE_ENV === 'development';
  const DEFAULT_LANGUAGE = env.DEFAULT_LANGUAGE || 'en';
  const PUBLIC_URL = env.NEXT_PUBLIC_URL || `http://localhost:${PORT}`;
  return {
    // Core
    NODE_ENV,
    VERSION,
    DEFAULT_LANGUAGE,
    PUBLIC_URL,
    API_URL: env.NEXT_PUBLIC_API_URL || `${PUBLIC_URL}/api`,
    // WebSockets
    WS_URL:
      env.NEXT_PUBLIC_WS_URL || (IS_DEVELOPMENT ? PUBLIC_URL.replace('http', 'ws').replace('https', 'wss') : undefined),
    WS_LOGGER_SOURCE: env.NEXT_PUBLIC_WS_LOGGER_SOURCE || LOGGER_SOURCE,
    // Auth
    ENABLE_AXIOS_CREDENTIALS: env.NEXT_PUBLIC_ENABLE_AXIOS_CREDENTIALS === 'true',
    // Feature Flags
    FEATURE_FLAG_URL: env.NEXT_PUBLIC_FEATURE_FLAG_URL,
    FEATURE_FLAG_CLIENT_KEY: env.NEXT_PUBLIC_FEATURE_FLAG_CLIENT_KEY,
    FEATURE_FLAG_APP_NAME: env.NEXT_PUBLIC_FEATURE_FLAG_APP_NAME,
    FEATURE_FLAG_REFRESH_INTERVAL: parseInt(env.NEXT_PUBLIC_FEATURE_FLAG_REFRESH_INTERVAL || '30'),
    // Subscriptions
    STRIPE_PRICE_BUSINESS: env.NEXT_PUBLIC_STRIPE_PRICE_BUSINESS,
    STRIPE_PRICE_PRO: env.NEXT_PUBLIC_STRIPE_PRICE_PRO,
    STRIPE_PRICE_STARTER: env.NEXT_PUBLIC_STRIPE_PRICE_STARTER,
    STRIPE_PUBLISHABLE_KEY: env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    // Contact
    SHEETS_ENDPOINT: env.NEXT_PUBLIC_SHEETS_ENDPOINT,
    SHEETS_TAB_ID: env.NEXT_PUBLIC_SHEETS_TAB_ID,
  };
}

import { useRouter } from 'next/compat/router';
import { useEffect } from 'react';
import equalityFn from 'zustand/shallow';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { Routes, routeToUrl } from '~/modules/routes';
import { userSessionStore, useUserSessionStore } from '~/stores/userSessionStore';
import { EntityStoreStatuses } from '~/util/createFetchStore';
import { usePushRoute } from '~/util/routes';

export function RequireAuth({ children, showLoading = true, redirectIfNotLoggedIn = false }) {
  const {
    push,
    pathname,
    query: { auth_type, next },
  } = useRouter();
  const pushRoute = usePushRoute();
  const [user, status] = useUserSessionStore((state) => [state.data, state.status], equalityFn);

  useEffect(
    function checkIsLoggedIn() {
      // Always get the latest state from the store within effects
      const { status } = userSessionStore.getState();
      // If we haven't yet started loading the user, do so now
      if (status === EntityStoreStatuses.UNINITIALIZED) {
        userSessionStore.fetch();
      }
      if (status === EntityStoreStatuses.ERROR) {
        // Login failed, redirect to signin
        if (redirectIfNotLoggedIn) {
          pushRoute('/auth/[auth_type]', { auth_type: 'signin' });
        }
        return;
      }
      if (status === EntityStoreStatuses.LOADED && pathname === '/auth/[auth_type]' && auth_type === 'signin') {
        // Login success in signin page (redirect away from signin page)
        push((next as string) || routeToUrl(Routes.AFTER_LOGIN));
        return;
      }
    },
    [status, redirectIfNotLoggedIn, pathname, auth_type, next],
  );

  if (status === EntityStoreStatuses.LOADING) {
    return showLoading ? (
      <div className="flex flex-col container py-96 items-center">
        <div className="flex">
          <LoadingSpinner />
          <span className="pb-2 pl-2">Authenticating, please wait...</span>
        </div>
      </div>
    ) : null;
  }

  if (!user) {
    return null;
  }

  return children;
}

import { IStorageProvider, IToggle, InMemoryStorageProvider, UnleashClient } from 'unleash-proxy-client';
import { SetState, createStore } from 'wired-store';
import {
  FEATURE_FLAG_APP_NAME,
  FEATURE_FLAG_CLIENT_KEY,
  FEATURE_FLAG_REFRESH_INTERVAL,
  FEATURE_FLAG_URL,
  IS_DEVELOPMENT,
  IS_PRODUCTION,
} from '~/modules/config';
import { FeatureFlag } from '~/modules/feature_flags';
import { handleNetworkWarning } from '~/modules/utilities/axios_utils';
import { isClient, logger } from '~/modules/utilities/cross_env_utils';
import { deepEquals, emptyObject } from '~/modules/utilities/object_utils';

export const enum FeatureFlagStoreStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  LOADING = 'LOADING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export interface FeatureFlagsState {
  status: FeatureFlagStoreStatus;
  featureFlags: Record<FeatureFlag, boolean>;
  unleash?: UnleashClient;
  context?: any;
}

const initialState: FeatureFlagsState = {
  status: FeatureFlagStoreStatus.UNINITIALIZED,
  featureFlags: emptyObject(),
};

export const featureFlagsStore = createStore(
  initialState,
  (set: SetState<FeatureFlagsState>, get: GetState<FeatureFlagsState>) => ({
    async getFeatureFlags({
      context,
      setValues,
      storageProvider,
    }: {
      context?: any;
      setValues?: Record<FeatureFlag, boolean>;
      storageProvider?: IStorageProvider;
    } = {}): Promise<void> {
      if (!FEATURE_FLAG_URL) {
        throw new Error('Cannot fetch feature flags without "FEATURE_FLAG_URL" env var');
      }
      if (!FEATURE_FLAG_CLIENT_KEY) {
        throw new Error('Cannot fetch feature flags without "FEATURE_FLAG_CLIENT_KEY" env var');
      }
      if (!FEATURE_FLAG_APP_NAME) {
        throw new Error('Cannot fetch feature flags without "FEATURE_FLAG_APP_NAME" env var');
      }

      let unleash = get().unleash;

      // If we already created unleash client, and the context did not change, skip re-fetching FFs.
      if (unleash && deepEquals(get().context, context)) {
        // If current featureFlags differ from defaultValues, update them to the new defaultValues.
        if (setValues && !deepEquals(get().featureFlags, setValues)) {
          set(function setFeatureFlags(draft) {
            draft.featureFlags = setValues;
            logger.debugIfDev((_) =>
              _(
                `Feature Flags Set${context ? ` (${JSON.stringify(context)}}` : ''}\n${JSON.stringify(
                  setValues,
                  null,
                  2,
                )}`,
                'feature_flags_store',
              ),
            );
          });
        }
        return Promise.resolve();
      }

      // Create a promise that will resolve (or reject) based on the status state of this store.
      // When the status changes to DONE, we resolve the promise, if it changes to ERROR, we reject it.
      // This promise gets returned to the caller of the function so they can wait for FFs to be loaded.
      let resolvePromise: undefined | (() => void);
      let rejectPromise: undefined | (() => void);
      const statusPromise = new Promise<void>((resolve, reject) => {
        resolvePromise = resolve;
        rejectPromise = reject;
      });
      let unsubscribe: undefined | (() => void) = featureFlagsStore.subscribe((status) => {
        switch (status) {
          case FeatureFlagStoreStatus.DONE: {
            unsubscribe?.();
            unsubscribe = undefined;
            resolvePromise?.();
            resolvePromise = undefined;
            break;
          }
          case FeatureFlagStoreStatus.ERROR: {
            unsubscribe?.();
            unsubscribe = undefined;
            rejectPromise?.();
            rejectPromise = undefined;
            break;
          }
          default: {
            /* noop */
          }
        }
      }, selectStatus);

      try {
        if (unleash) {
          unleash.stop();
        }

        unleash = new UnleashClient({
          url: FEATURE_FLAG_URL,
          clientKey: FEATURE_FLAG_CLIENT_KEY,
          appName: FEATURE_FLAG_APP_NAME,
          refreshInterval: FEATURE_FLAG_REFRESH_INTERVAL,
          environment: IS_PRODUCTION ? 'production' : 'development',
          disableRefresh: isClient,
          fetch: isClient ? undefined : (globalThis as AnyObject).fetch,
          storageProvider: isClient ? undefined : storageProvider || new InMemoryStorageProvider(),
        });

        set(function beginGetFeatureFlags(draft) {
          draft.unleash = unleash;
          draft.context = context;
          draft.status = FeatureFlagStoreStatus.LOADING;
          if (setValues) {
            draft.featureFlags = setValues;
            logger.debugIfDev((_) =>
              _(
                `Feature Flags Set${context ? ` (${JSON.stringify(context)}}` : ''}\n${JSON.stringify(
                  setValues,
                  null,
                  2,
                )}`,
                'feature_flags_store',
              ),
            );
          }
        });

        if (context) {
          unleash.updateContext(context);
        }

        // listening to update because it will also be emitted on initialization,
        // we don't need to handle ready or initialized.
        unleash.on('update', () => {
          const featureFlags = unleash!
            .getAllToggles()
            .reduce((accumulator: Record<string, boolean>, current: IToggle) => {
              accumulator[current.name] = current.enabled;
              return accumulator;
            }, {}) as FeatureFlagsState['featureFlags'];

          set(function endGetFeatureFlags(draft) {
            draft.featureFlags = featureFlags;
            draft.status = FeatureFlagStoreStatus.DONE;
            logger.debugIfDev((_) =>
              _(
                `Feature Flags Response${get().context ? ` (${JSON.stringify(get().context)})` : ''}\n${JSON.stringify(
                  featureFlags,
                  null,
                  2,
                )}`,
                'feature_flags_store',
              ),
            );
          });
        });

        unleash.on('error', () => {
          logger.error('Encountered error starting feature flag service.', 'feature_flags_store');
          set(function getFeatureFlagsFailed(draft) {
            draft.status = FeatureFlagStoreStatus.ERROR;
          });
        });

        // We have to setup all the promise and event handlers before calling start.
        // This should be the last thing we do in this function, other than waiting the promise.
        unleash.start();

        logger.debugIfDev((_) =>
          _(`Feature Flags Request${context ? ` (${JSON.stringify(context)})` : ''}`, 'feature_flags_store'),
        );
      } catch (error) {
        handleNetworkWarning(error);
        logger.error('Encountered error starting feature flag service.', 'feature_flags_store');
        set(function getFeatureFlagsFailed(draft) {
          draft.status = FeatureFlagStoreStatus.ERROR;
        });
      }

      return statusPromise;
    },
    reset() {
      set(function reset() {
        return initialState;
      });
    },
  }),
  {
    storeName: 'feature_flags_store',
    warnAgainstAnonymousActions: IS_DEVELOPMENT,
  },
);

export function selectStatus(state: FeatureFlagsState): FeatureFlagsState['status'] {
  return state.status;
}

export function selectfeatureFlags(state: FeatureFlagsState): FeatureFlagsState['featureFlags'] {
  return state.featureFlags;
}

import { useRouter } from 'next/compat/router';
import type { Route } from 'nextjs-routes';
import { deepEquals } from '~/modules/utilities/object_utils';
import { extractURLComponents } from '~/modules/utilities/routing_utils';

export type Pathname = Route['pathname'];

export function upsertQueryParams(...args: Array<[key: string, value: string]>) {
  const url = typeof window === 'undefined' ? '' : window.location.href;
  if (!url) {
    return '';
  }

  const { queryString } = extractURLComponents(url);
  const params = new URLSearchParams(queryString || '');

  for (const [key, value] of args) {
    params.set(key, value);
  }

  return params.toString();
}

export function usePushRoute() {
  const router = useRouter();
  return function pushRoute<
    Pathname extends Route['pathname'] = Route['pathname'],
    Query extends Route['query'] = Route['query'],
  >(pathname: Pathname, query?: Query) {
    if (router.pathname !== pathname || !deepEquals(router.query, query)) {
      router.push({ pathname, query });
    }
  };
}

import axios from 'axios';
import { API_URL } from '~/modules/config';
import { selectData } from '~/modules/utilities/axios_utils';

export function authenticateUserSession() {
  return axios
    .get(`${API_URL}/auth`)
    .catch(async function attemptRefresh() {
      return axios.get(`${API_URL}/auth/refresh`);
    })
    .then(selectData);
}

import { AxiosError } from 'axios';
import { useEffect, useRef } from 'react';
import { ApiResponse, handleNetworkError } from '~/modules/utilities/axios_utils';
import { usePushRoute } from '~/util/routes';

// Make an API request to `/api/{path}`
export function apiRequest(path, method = 'GET', data = undefined) {
  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 'error') {
        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = 'GET', data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export class CustomError extends Error {
  constructor(
    public readonly code,
    message,
  ) {
    super(message);
  }
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

export const onApiError = (pushRoute: ReturnType<typeof usePushRoute>) => (error: AxiosError<ApiResponse>) => {
  handleNetworkError(error);
  if (error.response?.status === 401 || error.response?.status === 403) {
    pushRoute('/auth/[auth_type]', { auth_type: 'signin' });
  }
  return null;
};

import { UserSession } from '~/dtos/user_session';
import { handleException } from '~/modules/utilities/cross_env_utils';
import { authenticateUserSession } from '~/services/userSessionService';
import { createFetchStore, EntityStoreState, selectData as selectUserData } from '~/util/createFetchStore';

type SessionStoreState = EntityStoreState<UserSession>;

export const [userSessionStore, useUserSessionStore] = createFetchStore<UserSession>({
  storeName: 'userSessionStore',
  errorStatusOnNullResponse: true,
  fetch: authenticateUserSession,
  handleException: (exception) => handleException(exception, { logLevel: 'warn' }),
});

export function selectUser(state: SessionStoreState) {
  return selectUserData(state);
}

export function useUser() {
  return useUserSessionStore(selectUser) as unknown as $.User;
}

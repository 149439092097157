import '~/modules/utilities/load_client_config'; // must be the first import
// prettier-ignore
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useRouter } from 'next/compat/router';
import { Footer } from '~/components/Footer';
import { Meta } from '~/components/Meta';
import { Navbar } from '~/components/Navbar';
import { RequireAuth } from '~/components/RequireAuth';
import { IS_DEVELOPMENT } from '~/modules/config';
import { featureFlagsStore } from '~/modules/stores/feature_flags_store';
import { logger, setCrossEnvLogger } from '~/modules/utilities/cross_env_utils';
import '~/util/analytics';
import { WsLogger } from '~/util/wsLogger';
import '../styles/global.css';

const wsLogger = new WsLogger();

setCrossEnvLogger(wsLogger);

const queryClient = new QueryClient();

if (typeof window !== 'undefined') {
  logger.log(
    `${process.env.NEXT_PUBLIC_APP_NAME} v${process.env.NEXT_PUBLIC_APP_VERSION} built on ${new Date(
      process.env.NEXT_PUBLIC_BUILD_TIMESTAMP,
    ).toLocaleString()}${IS_DEVELOPMENT ? ' (Development)' : ''}`,
  );
  // The stores global allows us to access stores during development
  if (IS_DEVELOPMENT && !window.stores) {
    window.stores = {};
  }
  featureFlagsStore.getFeatureFlags({
    setValues: window.stores.FEATURE_FLAGS,
  });
}

export default function App({ Component, pageProps }) {
  const router = useRouter();
  return (
    <Meta>
      <QueryClientProvider client={queryClient}>
        <RequireAuth showLoading={false}>
          <Navbar bgColor="bg-white" />
        </RequireAuth>
        {router.pathname === '/auth/[auth_type]' && router.query.auth_type === 'signin' ? (
          <Component {...pageProps} />
        ) : (
          <RequireAuth showLoading={false} redirectIfNotLoggedIn>
            <Component {...pageProps} />
          </RequireAuth>
        )}
        <RequireAuth showLoading={false}>
          <Footer size="md" bgColor="bg-white" bgImage="" bgImageOpacity={1} textColor="" sticky={true} />
        </RequireAuth>
      </QueryClientProvider>
    </Meta>
  );
}

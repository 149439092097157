/**
 * This is no longer used in favor of using 'nextjs-routes' npm package on the client:
 *
 * https://www.npmjs.com/package/nextjs-routes
 *
 * This might come in handy if we need to create custom logic for specific pages on the server.
 */
export const Routes = {
  HOME: '/',
  DOCS: 'docs',
  LOGIN: 'auth/signin',
  AFTER_LOGIN: 'rankings/stocks/cannabis',
} as const;

export type Route = (typeof Routes)[keyof typeof Routes];
export const ROUTES: string[] = Object.values(Routes);

export function getFinalPath(route: Route) {
  const index = route.lastIndexOf('/');
  if (index === -1) {
    return route;
  }
  return route.slice(index + 1);
}

export function routeToUrl(route: Route) {
  if (route === Routes.HOME) {
    return route;
  }
  return `/${route}`;
}

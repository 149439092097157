import { Draft } from 'immer';
import { createUseStore } from 'wired-react';
import { createStore } from 'wired-store';
import { IS_DEVELOPMENT } from '~/modules/config';
import { handleException } from '~/modules/utilities/cross_env_utils';

export const EntityStoreStatuses = {
  UNINITIALIZED: 'UNINITIALIZED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
} as const;
export type EntityStoreStatus = (typeof EntityStoreStatuses)[keyof typeof EntityStoreStatuses];

export interface EntityStoreState<T = unknown> {
  status: EntityStoreStatus;
  initialData: T;
  data: T;
}

export function createFetchStore<T = unknown>(options: {
  storeName: string;
  fetch: () => Promise<T>;
  initialData?: T | null;
  fetchImmediately?: boolean;
  errorStatusOnNullResponse?: boolean;
  handleException?: (exception: unknown) => void;
}) {
  const initialState: EntityStoreState<T> = {
    status: EntityStoreStatuses.UNINITIALIZED,
    initialData: options?.initialData ?? null,
    data: null,
  };

  const fetchStore = createStore(
    initialState,
    (set, get) => ({
      async fetch(fetcher = options?.fetch, exceptionHandler = options?.handleException ?? handleException) {
        set(function beginLoading(draft) {
          draft.status = EntityStoreStatuses.LOADING;
        });
        try {
          const data = await fetcher();
          set(function finishLoading(draft) {
            draft.data = data as Draft<T>;
            if (options?.errorStatusOnNullResponse && data == null) {
              draft.status = EntityStoreStatuses.ERROR;
            } else {
              draft.status = EntityStoreStatuses.LOADED;
            }
          });
        } catch (error) {
          set(function failLoading(draft) {
            draft.status = EntityStoreStatuses.ERROR;
          });
          exceptionHandler(error);
        }
        return get().data;
      },
    }),
    {
      storeName: options?.storeName,
      warnAgainstAnonymousActions: IS_DEVELOPMENT,
      attachTo: IS_DEVELOPMENT && globalThis.stores,
    },
  );

  if (options.fetchImmediately) {
    fetchStore.fetch();
  }

  const useFetchStore = createUseStore(fetchStore);
  return [fetchStore, useFetchStore] as const;
}

export function selectData<T = unknown>(state: EntityStoreState<T>): T {
  return state.data;
}
export function selectStatus<T = unknown>(state: EntityStoreState<T>): EntityStoreStatus {
  return state.status;
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios';
import { IS_DEVELOPMENT } from '~/modules/config';
import { serializeAxiosError } from '~/modules/utilities/axios_utils';
import { printJson } from '~/modules/utilities/json_utils';

export const PaginatedLoadingStates = {
  UNINITIALIZED: 'UNINITIALIZED',
  LOADING: 'LOADING',
  LOADING_MORE: 'LOADING_MORE',
  LOADED: 'LOADED',
} as const;
export type PaginatedLoadingState = (typeof PaginatedLoadingStates)[keyof typeof PaginatedLoadingStates];

export interface PaginatedResponse<T = unknown> {
  results: T[];
  totalResults: number;
  currentPage: number;
  totalPages: number;
  hasNextPage: boolean;
}

type LogFn = (...args: unknown[]) => void;
type DevLogFn = (_: LogFn) => void;

export type LogLevel = keyof Logger;

export type Logger = {
  log: LogFn;
  warn: LogFn;
  error: LogFn;
  debug: LogFn;
  verbose: LogFn;
};

export type EnhancedLogger = Logger & {
  // IfDev functions only log in development mode
  logIfDev: (_: DevLogFn) => void;
  warnIfDev: (_: DevLogFn) => void;
  errorIfDev: (_: DevLogFn) => void;
  debugIfDev: (_: DevLogFn) => void;
  verboseIfDev: (_: DevLogFn) => void;
};

// https://stackoverflow.com/a/57774317/706442
// @ts-ignore
export const isClient = typeof window !== 'undefined' && typeof window.document !== 'undefined';
// @ts-ignore
export const isServer = typeof module === 'object' && typeof module.exports === 'object';

export function assertIsClient() {
  if (!isClient) {
    throw new Error('Can only be called on clients.');
  }
}

export function assertIsServer() {
  if (!isServer) {
    throw new Error('Can only be called on servers.');
  }
}

export const CORRELATION_ID_PARAM = 'x-correlation-id';

export let logger: EnhancedLogger;

// Initialize cross-env logger with console enhanced with 'verbose' method (which acts as a shim to 'debug' method)
setCrossEnvLogger({ ...console, verbose: (...args: unknown[]) => console.debug(...args) });

export function setCrossEnvLogger<T extends Logger>(crossEnvLogger: T) {
  logger = enhanceLogger<T>(crossEnvLogger);
  return logger;
}

export function enhanceLogger<T extends Logger>(logger: Logger, forceLogs = false): T & EnhancedLogger {
  const log = (...args: unknown[]) => logger.log(...args);
  const warn = (...args: unknown[]) => logger.warn(...args);
  const error = (...args: unknown[]) => logger.error(...args);
  const debug = (...args: unknown[]) => logger.debug(...args);
  const verbose = (...args: unknown[]) => logger.verbose(...args);

  return Object.assign(logger, {
    // IfDev functions only log in development mode
    logIfDev: (_: DevLogFn) => (forceLogs ? _(log) : runIfDev(_, log)),
    warnIfDev: (_: DevLogFn) => (forceLogs ? _(warn) : runIfDev(_, warn)),
    errorIfDev: (_: DevLogFn) => (forceLogs ? _(error) : runIfDev(_, error)),
    debugIfDev: (_: DevLogFn) => (forceLogs ? _(debug) : runIfDev(_, debug)),
    verboseIfDev: (_: DevLogFn) => (forceLogs ? _(verbose) : runIfDev(_, verbose)),
  }) as T & EnhancedLogger;
}

export interface HandleExceptionOptions {
  logger?: Logger;
  logLevel?: LogLevel;
  context?: string;
}

export function handleException(exception: unknown, options: HandleExceptionOptions = {}) {
  let optionsAsRequired = options as Required<HandleExceptionOptions>;

  // Populate required options with defaults
  if (!optionsAsRequired.logger) {
    optionsAsRequired.logger = logger;
  }
  if (!optionsAsRequired.logLevel) {
    optionsAsRequired.logLevel = 'error';
  }

  // Extra indentation is used to prevent scope related confusion with 'logger' variable
  {
    const { logger, logLevel, context } = optionsAsRequired;

    if (axios.isAxiosError(exception)) {
      logger[logLevel](`AxiosError: ${printJson(serializeAxiosError(exception))}`);
      return;
    }

    const exceptionType = typeof exception;
    if (exceptionType === 'object') {
      const exceptionAsRecord = exception as AnyObject;
      logger[logLevel](
        exceptionAsRecord.stack || exceptionAsRecord.message || String(exception),
        context || exceptionAsRecord.name,
      );
    } else if (!!context) {
      logger[logLevel](String(exception), context);
    } else {
      logger[logLevel](String(exception));
    }
  }
}

export function throwDevelopmentOrLog(error: Error, logLevel: keyof Logger = 'error') {
  if (IS_DEVELOPMENT) {
    throw error;
  }
  handleException(error, { logger, logLevel });
}

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export function runIfDev(fn: Function, ...args: any): void {
  if (IS_DEVELOPMENT) {
    fn(...args);
  }
}
